/*
 * Copyright (C) 2021 The Android Open Source Project
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/* Dark theme (default) */
#vsm-body, #vsm-body.dark-theme {
    --toggle-off-bg: red;
    --toggle-off-fg: white;
  }
  /* Light theme */
  #vsm-body.light-theme {
    --toggle-off-bg: red;
    --toggle-off-fg: white;
  }
  
  .toggle-control.toggle-off {
    background-color: var(--toggle-off-bg);
    color: var(--toggle-off-fg);
    border-radius: 10px;
  }
  
  